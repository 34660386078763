import React from "react";

const TopTextSection = ({ details }) => {
  const firstWordIndex = Math.min(
    details.positionE.indexOf(" ") === -1
      ? 10000
      : details.positionE.indexOf(" "),
    details.positionE.indexOf("-") === -1
      ? 10000
      : details.positionE.indexOf("-")
  );
  return (
    <>
      <p
        style={{
          color:
            details.department === "BIMSolutions"
              ? "#7662ED"
              : details.department === "expertServices"
              ? "#024DFF"
              : details.department === "designBureau"
              ? "#FFC003"
              : "#00D5FD",
          margin: "6px 6px 0px 0px",
          fontSize: "18px",
          fontWeight: "700",
        }}
      >
        {details.fullNameA}
      </p>
      <p
        style={{
          textDecorationLine: "none",
          fontSize: "11px",
          color: "rgb(1,0,57)",
          fontWeight: "700",
          margin: "0px 6px 6px 0px",
        }}
      >
        {details.fullNameE}
      </p>
      <p
        // className="position"
        style={{
          margin: "0px",
          color: "rgb(166, 170, 173)",
          fontSize: "12px",
          marginBottom: "0px",
        }}
      >
        {details.positionA}
      </p>
      <p
        // className="position"
        style={{
          margin: "0px",
          color: "rgb(166, 170, 173)",
          fontSize: "11px",
          paddingBottom: "10px",
          marginBottom: "14px",
        }}
      >
        <span
          style={{
            borderBottom: "4px solid #09feba",
            paddingBottom: "10px",
          }}
        >
          {firstWordIndex === 10000
            ? details.positionE
            : details.positionE.slice(
                0,
                firstWordIndex >= 5 ? firstWordIndex : 5
              )}
        </span>
        {firstWordIndex === 10000
          ? ""
          : details.positionE.slice(firstWordIndex >= 5 ? firstWordIndex : 5)}
      </p>

      <p
        // className="phone"
        style={{
          color: "#010039",
          fontSize: "11px",
          // fontWeight: "700",
          margin: "0",
          marginTop: "10px",
        }}
      >
        +{details.location.phone}{" "}
        {details.location.phone.length === 3
          ? ` ${details.phoneNumber.slice(0, 2)} 
                ${details.phoneNumber.slice(2, 5)} 
                ${details.phoneNumber.slice(5, 9)}`
          : ` ${details.phoneNumber.slice(0, 3)} 
                ${details.phoneNumber.slice(3, 6)} 
                ${details.phoneNumber.slice(6, 10)}`}
      </p>
      <p
        style={{
          fontSize: "11px",
          // fontWeight: "700",
          color: "rgb(1,0,57)",
          margin: "0",
        }}
      >
        <a
          // className="links"
          style={{
            textDecorationLine: "none",
            fontSize: "11px",
            // fontWeight: "700",
          }}
          href="https://bimventures.com/"
        >
          BIMventures.com
        </a>
      </p>

      <p
        // className="phone"
        style={{
          color: "#010039",
          fontSize: "11px",
          // fontWeight: "700",
          margin: "0",
        }}
      >
        {details.location.label === "Saudi Arabia"
          ? "Riyadh, Saudi Arabia"
          : details.location.label}
      </p>
    </>
  );
};

export default TopTextSection;
