import React from "react";
import { Button } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

let timer;

const ActionButtons = ({ copySignature }) => {
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = async () => {
    copySignature();
    setOpen(() => true);
    clearTimeout(timer);
    timer = setTimeout(() => {
      setOpen(() => false);
    }, 2000);
  };

  const TextOnlyTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
    color: black;
    background-color: white;
`);

  return (
    <>
      {" "}
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div className="actionButtonContainer">
          <TextOnlyTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied to clipboard"
          >
            <Button
              color="primary"
              variant="contained"
              type="click"
              onClick={handleTooltipOpen}
            >
              Copy Signature
            </Button>
          </TextOnlyTooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default ActionButtons;
