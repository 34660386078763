import "./App.css";
import SignForm from "./components/signForm/signForm";
import SignGen from "./components/signGen/signGen";
import { useState, useRef } from "react";
import HelpModal from "./components/helpModal/helpModal";
import ScrollButton from "./components/shared/scrollButton";

function App() {
  const [details, setDetails] = useState({
    fullNameE: "",
    fullNameA: "",
    positionE: "",
    positionA: "",
    phoneNumber: "",
    department: "general",
    location: {
      code: "SA",
      label: "Saudi Arabia",
      phone: "966",
    },
  });

  const [open, setOpen] = useState(false);
  const finalRef = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const detailsHandler = (detailsObject) => {
    setDetails(detailsObject);
  };

  return (
    <div className="bodyDiv">
      <div className="container">
        <ScrollButton finalRef={finalRef}></ScrollButton>
        <HelpModal open={open} handleClose={handleClose}></HelpModal>
        <SignForm
          detailsHandler={detailsHandler}
          details={details}
          handleOpen={handleOpen}
        ></SignForm>
        <SignGen details={details}></SignGen>
        <div ref={finalRef}></div>
      </div>
    </div>
  );
}

export default App;
