import React from "react";

const BottomImageContainer = () => {
  return (
    <>
      <div>
        <img
          // className="imageContainer"
          height={42}
          style={{
            display: "flex",
            margin: "11px 0px 2px 0px",
            maxWidth: "100%",
          }}
          src="https://lh3.googleusercontent.com/d/1ZVlqQbrNODuYO_91C2fqSFkg_mT27HT2"
          alt="LogoFull"
        />
      </div>
      <div>
        <img
          // className="imageContainer"
          height={72}
          style={{
            display: "flex",
            margin: "5px 0px 2px 0px",
            maxWidth: "100%",
          }}
          src="https://lh3.googleusercontent.com/d/1eGcRkMWavSTlTYQ3aZUZP-x440XQ92qn"
          alt="LogoFull"
        />
      </div>

      <table>
        <tbody>
          <tr>
            <td>
              <a
                href="https://twitter.com/bim_ventures"
                style={{
                  textDecorationLine: "none",
                  color: "rgb(1,0,57)",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                <img
                  style={{
                    margin: "5px 0 5px 0",
                  }}
                  height={16}
                  src="https://lh3.googleusercontent.com/d/1Ti_taYdF0l6J6hpZB9USd1vKPjTV6Pdb"
                  alt="twitter"
                />
              </a>
            </td>
            <td>
              <a
                href="https://www.linkedin.com/company/bimventures"
                style={{
                  textDecorationLine: "none",
                  color: "rgb(1,0,57)",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                <img
                  height={16}
                  style={{
                    margin: "5px 0 5px 0",
                  }}
                  src="https://lh3.googleusercontent.com/d/14T69JJY5qFPRMk-Sa62_dClgzQYqVXrE"
                  alt="linkedIn"
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default BottomImageContainer;
